<template>
  <section>
      <div class="row" style="font-family: Montserrat;">
         <b-col cols="12" class="mt-md-5 mt-2 w-100 h-100 d-flex flex-column justify-content-between align-items-center">
      <div class="baldan-image text-center d-flex justify-content-center mt-2 mt-md-4 w-100">
<LazyImage
          :src="`https://images.quizell.com/website/Frame%20(6)%20(1).png`"
          style="width: 143px; height: 200px;"
          :alt="`Quizell Product Quiz`"
          :title="`Quizell Product Quiz`"
          class="rounded successImage" />
      </div>
      <p class="header mt-2 mt-md-4">
        YAYY!
      </p>
      <div class="w-md-75 mx-4">
        <p class="title mt-2 mt-md-4 px-5 mb-5">
          {{ translateCustomText(`EndPageText`) }}
        </p>
        <div class="text-center mt-5">
          <ElthButton
          class="mx-1 my-2"
           v-if="(typeof collectionLink.account_activation_url=='string')"
            @click="openShop(collectionLink.account_activation_url)"
          style="border: 1px solid #503A3C; background: linear-gradient(180deg, #FFDAD8 0%, #FFA282 99%); color:#503A3C"
          >
          {{ translateCustomText(`Activate your account`) }}

          </ElthButton>
      
            <!-- <b-button pill class="outline-button mx-1  my-2"  @click="openShop(collectionLink.live_preview_link)">{{ translateCustomText(`Shop Link`) }}</b-button> -->
          
          
</div>
<!-- <div class="text-center font-weight-bold">
          <div> {{ translateCustomText(`Kind regards`) }},</div> 
          <div> {{ translateCustomText(`The Elth team`) }}</div>
</div> -->
        <div class=""  v-if="false">
        <div class="credentials-detail text-center font-weight-bold mb-4" style="line-height:2">
          <div class="email mb-2">ðŸ‘‰ Email:<span class="ml-2"> {{ translateCustomText(`The email associated with your account`) }}</span></div>
          <div class="password  mb-2">ðŸ‘‰ Password:<span class="ml-2">BaldanxElth</span></div>
        </div>
        <div class=" d-flex justify-content-center mt-2 mt-md-4">
           <ElthButton
            @click="window.open('https://baldan.elth.store/account/login','_blank')"
            href="" target="_blank"
          >
          {{ translateCustomText(`Log in to your account`) }}

          </ElthButton>
        </div>
        </div>
      </div>
      </b-col>
      </div>
  </section>
</template>
<script>
import ElthButton from "./ElthButton.vue";
import TranslationMixin from "../../../customize/mixin/TranslationMixin";
export default {
props:['quizId','collectionLink'],
mixins: [ TranslationMixin],
components:{ElthButton},
methods:{
  translateCustomText(key) {
    return this.translateToItalian(key, this.quizId);
  },
   openShop(link) {
      if (link) {
        if(link.startsWith("https")){
           window.open(link, "_blank");
        } else {
           let url = `https://${link}`;
           window.open(url, "_blank");
        }
        
      }
    }
}
}
</script>
<style scoped>
.header{
        font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 27px;
          text-align: center;
          color: #503A3C;
}
.title{
 color: #503A3C;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 700px;
}
.filled-button {
background: #9C4629;
border-radius: 30px;
padding: 6px 24px;
font-weight: 700;
height: 43px;
font-size: 14px;
 border: 1px solid #9C4629!important;
transition: 0.5s ease all;
}
.filled-button:hover {
color: #9C4629;
border: 1px solid #9C4629!important;
background: transparent;
}
.filled-button:focus {
color: #ffff;
background: #9C4629;
border: 1px solid #9C4629;
box-shadow: inset 7px 6px 28px 1px rgba(156, 54, 41, 0.24) !important;
}
.filled-button:not(:disabled):not(.disabled):active {
background-color: transparent !important;
color: #9C4629;
border: 1px solid #9C4629;
box-shadow: inset 7px 6px 28px 1px rgba(156, 54, 41, 0.24) !important;
}
.filled-button {
  background: #9C4629;
  border-radius: 30px;
  padding: 6px 24px;
  font-weight: 700;
   border: 1px solid #9C4629;
  height: 43px;
  font-size: 14px;
  transition: 0.5s ease all;
   white-space:nowrap;
}
.filled-button:hover {
  color: #9C4629!important;
  border: 1px solid #9C4629;
  background: transparent;
}
.outline-button:hover {
  background: #9C4629;
  color: #fff;
   border: 1px solid #9C4629;
}
.outline-button {
  color: #9C4629;
  border: 1px solid #9C4629;
  padding: 6px 24px;
  height: 43px;
  color: #9C4629;
text-align: center;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
  background: transparent;
  transition: 0.5s ease all;
  white-space:nowrap;
}
.filled-button:active {
  background-color: transparent !important;
  color: #9C4629;
  border: 1px solid #9C4629;
  box-shadow: inset 7px 6px 28px 1px rgba(156, 54, 41, 0.24) !important;
}
.outline-button:active {
  background-color: #9C4629 !important;
  box-shadow: inset 7px 6px 28px 1px rgba(0, 0, 0, 0.24) !important;
  /* Moving button 4px to y-axis */
}
@media only screen and (max-width: 767px) {
   .filled-button,.outline-button{
    font-size:12px;
  }
  .header{
        font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 27px;
          text-align: center;
          color: #503A3C;
}
}
</style>